import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import groupePropressImage from '@/assets/images/logo_groupe_propress.png'

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
            <Link color="inherit" href="https://groupepropress.fr/">
                Groupe ProPress
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
            }}
        >
            <Container maxWidth="sm"
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: 'center'
                }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    <img
                        src={groupePropressImage}
                        alt="Logo"
                        style={{
                            // height: 50, width: 'auto',
                            height: 'auto',
                            maxWidth: '100%',
                        }}
                    />
                    <Link href='https://cgu.kioskpress.fr/' target="_blank">Conditions Générales d’Utilisation</Link>
                </Box>
                <Copyright />
            </Container>
        </Box>
    );
}