import { Alert, Avatar, Button, Checkbox, Container, FormControlLabel, Grid, Link, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useRegistrationMutation, useSignInMutation } from '@/stores/api';
import { useMemo } from 'react';
import { useLocation, useParams, Link as RRLink, useNavigate } from 'react-router-dom';

export default function SignUp() {

    const { code, ...rest } = useParams()
    const [register, { error, data, isSuccess }] = useRegistrationMutation()
    const [signIn,] = useSignInMutation()
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try {
            const { token } = await signIn({
                email: data.get('email')?.toString(),
                password: data.get('code')?.toString(),
            }).unwrap()

            if (token) {
                navigate(`/`, { replace: true });
            } else {

                register({
                    email: data.get('email')?.toString(),
                    plainPassword: data.get('code')?.toString(),
                    firstName: data.get('firstName')?.toString(),
                    lastName: data.get('lastName')?.toString(),
                    code: data.get('code')?.toString(),
                })
            }
        } catch (error: any) {
            register({
                email: data.get('email')?.toString(),
                plainPassword: data.get('code')?.toString(),
                firstName: data.get('firstName')?.toString(),
                lastName: data.get('lastName')?.toString(),
                code: data.get('code')?.toString(),
            })
        }
    };

    const fieldError = (field: string) => {
        // @ts-ignore
        return error?.data?.errors?.form?.children[field]?.errors
    }
    const fieldHasError = (field: string): boolean => {

        return fieldError(field)?.length > 0;
    }
    const renderForm = useMemo(() => {
        if (isSuccess) {
            return null
        }
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="given-name"
                            name="firstName"
                            required
                            fullWidth
                            id="firstName"
                            label="Prénom"
                            autoFocus
                            error={fieldHasError('firstName')}
                            helperText={fieldError('firstName')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="lastName"
                            label="Nom"
                            name="lastName"
                            autoComplete="family-name"
                            error={fieldHasError('lastName')}
                            helperText={fieldError('lastName')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Adresse mail"
                            name="email"
                            autoComplete="email"
                            error={fieldHasError('email')}
                            helperText={fieldError('email')}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            error={fieldHasError('plainPassword')}
                            helperText={fieldError('plainPassword')}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="code"
                            label="Code"
                            id="code"
                            error={fieldHasError('code')}
                            defaultValue={code}
                            helperText={fieldError('code')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox name="isOptin" color="primary" required />}
                            label={
                                <Box>
                                    En m'inscrivant j'accepte les
                                    <Link href="https://cgu.kioskpress.fr/" variant="body2" target="_blank">
                                        &nbsp;CGV/CGU.
                                    </Link>
                                </Box>
                            }
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'common.white' }}
                >
                    S'inscrire
                </Button>

                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link component={RRLink} to={code ? `/authentication/signin/${code}` : "/authentication/signin"} variant="body2">
                            J'ai déjà un compte? Je me connecte
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        )
    }, [isSuccess, error, fieldError, fieldHasError])
    return (
        <Container component="main" maxWidth="xs" sx={{ width: '100%' }}>

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h6">
                    Inscription
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    {/* @ts-ignore */}
                    {error?.data && error?.data?.message && (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {/* @ts-ignore */}
                            {error?.data?.message}
                        </Alert>
                    )}
                    {data?.message && (
                        <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
                            {/* @ts-ignore */}
                            {data?.message}
                        </Alert>
                    )}
                    {renderForm}
                </Box>
            </Box>
        </Container>
    );
}