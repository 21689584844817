import useAuthentication from '@/hooks/useAuthentication';
import { useConfirmRegistrationMutation } from '@/stores/api';
import { Alert, CircularProgress, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


interface Props {
}

export default function ConfirmEmail(props: Props) {

    const { token: confirmationToken } = useParams()
    const navigate = useNavigate();
    const [confirmRegistation, { isLoading, error, }] = useConfirmRegistrationMutation()
    const { token: authToken } = useAuthentication()

    useEffect(() => {
        if (confirmationToken) {
            confirmRegistation(confirmationToken)
        }
    }, [confirmationToken, confirmRegistation])

    useEffect(() => {
        if (authToken) {
            navigate('/')
        }
    }, [authToken, navigate])

    return (
        <Container component="main" maxWidth="xs" sx={{ width: '100%' }}>

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {isLoading && (
                    <Typography component="h1" variant="h6" sx={{ mt: 2, mb: 2 }}>
                        Verification
                    </Typography>
                )}
                <Box sx={{ mt: 1 }}>
                    {/* @ts-ignore */}
                    {error?.data && error?.data?.message && (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {/* @ts-ignore */}
                            {error?.data?.message}
                        </Alert>
                    )}
                    {isLoading ? <CircularProgress /> : null}
                </Box>
            </Box>
        </Container>
    );
}