import IssueItem from '@/components/IssueItem';
import { useAppDispatch } from '@/stores';
import { useCategoriesQuery } from '@/stores/api';
import { setToken } from '@/stores/authSlice';
import { Issue } from '@/stores/types';
import { generateToken } from '@/utils/reader';
import { Box, CircularProgress, Container, Grid } from '@mui/material';

import Typography from '@mui/material/Typography';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
}

export default function KiokCode() {


    const isLoading = true;
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(setToken(process.env.REACT_APP_API_SECRETE!))
            navigate('/', { replace: true })
        }, 1000);

        return () => {
            clearTimeout(timeout)
        }
    }, [navigate, dispatch])

    const Loading = useMemo(() => {
        if (isLoading) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Typography variant='h4'>Verification...</Typography>
                    <CircularProgress color='primary' />
                </Box>
            )
        }
        return null;
    }, [isLoading])

    const onPressIssue = (issue: Issue) => () => {
        const jwt = generateToken(issue)
        window.location.href = `${process.env.REACT_APP_READER_URL}${jwt}`
    }

    return (
        <Container maxWidth={false} sx={{ flex: 1, }}>
            {Loading}
        </Container >
    );
}