import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import type { PersistConfig, } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt';

import Storage from 'localforage';
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import auth from './authSlice'
import { api } from './api';
import { createElement } from 'react';
import pkg from '../../package.json'

const encryptor = encryptTransform({
    secretKey: pkg.name,
    onError: function (error) {
        // Handle the error.
    },
})

const persistConfig: PersistConfig<any> = {
    key: pkg.name,
    version: 1,
    storage: Storage,
    whitelist: ['auth', 'shoppingList'],
    transforms: [encryptor]
}
// const expiresAuth = expireTransform("auth", {
//     autoExpire: true,
//     expireSeconds: 60,
//     // expireSeconds: 7,
//     expiredState: initialState, // Reset to empty array after expiry
// });
const rootReducer = combineReducers({
    auth: auth,
    [api.reducerPath]: api.reducer,
})
const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
) as typeof rootReducer

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(api.middleware)
})


export const persistor = persistStore(store)

// export const hydrateStores = (): Promise<void> => {
//     console.log('1')
//     return new Promise((resolve) => {
//         console.log('2')
//         persistStore(store, null, () => {
//             console.log('3')
//             resolve()
//         });
//         console.log('4')
//     })
// };

// export default function configureStore(onComplete: () => void): any {

//     hydrateStores()
//         .then(onComplete)
//         .catch((error: any) => { console.log('error loading', error) })
// }

setupListeners(store.dispatch)
// Infer the `RootState` and `AppDispatch` types from the store itself

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


export const withStores = (component: React.ElementType) => {
    return (props: any): React.ReactElement => {

        return (
            <Provider store={store}>
                {/* <ActionSheetProvider> */}
                {createElement(component, props)}
                {/* </ActionSheetProvider> */}
                {/* <C {...props} /> */}

            </Provider>
        )

    };
};