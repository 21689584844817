import { Alert, Avatar, Button, Container, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useResettingRequestPasswordMutation } from '@/stores/api';
import { useMemo } from 'react';


export default function ForgotPassword() {

    const [requestPassword, { error, data }] = useResettingRequestPasswordMutation()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        requestPassword(data.get('email')?.toString()!)
    };
    const renderForm = useMemo(() => {
        if (!data) {
            return (
                <>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Adresse mail"
                        name="email"
                        autoComplete="email"
                        type="email"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Réinitiliser
                    </Button>
                </>
            )
        }
        return null
    }, [data,])
    return (
        <Container component="main" maxWidth="xs" >

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h6">
                    Réinitialiser le mot de passe
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    {/* @ts-ignore */}
                    {error?.data && error?.data?.message && (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {/* @ts-ignore */}
                            {error?.data?.message}
                        </Alert>
                    )}
                    {data && !error && (
                        <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
                            {data?.message || "Vous allez recevoir un mail contenant les instructions à suivre."}
                        </Alert>
                    )}
                    {renderForm}
                </Box>
            </Box>
        </Container>
    );
}