import CryptoJS from 'crypto-js';
import { Issue } from "@/stores/types";

const urlEncodeBase64 = (signature: string): string => {
    signature = signature.replace(/(=+)$/g, '');
    signature = signature.replace(/\//g, '_');
    signature = signature.replace(/\+/g, '-');

    return signature;
};

const createPayload = (issue: Issue, authToken?: string) => {
    const userToken = authToken || process.env.REACT_APP_API_SECRETE;

    return {
        publication: {
            id: issue.publicationId,
            name: issue.publicationName,
            publication_number: {
                ...issue,
            },
            issue,
        },
        userData: {
            apiKey: userToken,
            api_key: userToken,
            kiosk: process.env.REACT_APP_KIOSK_ID
        },
        apiUrl: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/api" : "https://prod.lekiosknumerique.com/api",
    };
}

export const generateToken = (issue: Issue, authToken?: string) => {

    const header = {
        "alg": "HS256",
        "typ": "JWT"
    };
    const jsonHeader = JSON.stringify(header)
    const jsonBody = JSON.stringify(createPayload(issue, authToken))

    const base64Header = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(jsonHeader));
    const base64Body = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(jsonBody));

    const encodedHeader = urlEncodeBase64(base64Header);
    const encodedBody = urlEncodeBase64(base64Body);


    const signature = CryptoJS.HmacSHA256(`${encodedHeader}.${encodedBody}`, '_key');
    const encodedSignature = signature.toString(CryptoJS.enc.Base64);
    const urlSafeEncodedSignature = urlEncodeBase64(encodedSignature);

    return `${encodedHeader}.${encodedBody}.${urlSafeEncodedSignature}`
}