
import { Fragment } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Outlet } from 'react-router-dom';
import imagePath from '@/assets/images/background/3.jpeg'

export default function AuthenticationLayout() {

    return (
        <Fragment>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                {/* <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        // backgroundImage: 'url(https://source.unsplash.com/random)',
                        backgroundImage: `url(${imagePath})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                /> */}
                <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square
                    display={"flex"}
                    flexDirection="column"
                    alignContent={"center"}
                    justifyContent="center" >

                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={require('@/assets/images/ubeeqo.png')}
                            alt="Logo"
                            style={{
                                height: 'auto',
                                maxWidth: '100%',
                            }}
                        />
                        <Outlet />
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
}