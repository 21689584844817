import { Alert, Avatar, Button, Container, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useResettingResetPasswordMutation } from '@/stores/api';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';
import useAuthentication from '@/hooks/useAuthentication';


export default function ResetPassword() {

    const { token: resettingToken } = useParams()
    const navigate = useNavigate()
    const [reset, { error, data }] = useResettingResetPasswordMutation()

    const { token: authToken } = useAuthentication()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        reset({
            token: resettingToken!,
            password: data.get('password')?.toString()!,
            confirmation: data.get('confirm-password')?.toString()!,
        })
    };
    useEffect(() => {
        if (authToken) {
            navigate('/')
        }
    }, [authToken, navigate])

    const fieldError = (field: string) => {
        // @ts-ignore
        return get(error?.data?.errors?.form?.children, field)?.errors
    }
    const fieldHasError = (field: string): boolean => {

        return fieldError(field)?.length > 0;
    }
    const renderForm = useMemo(() => {
        if (!data) {
            return (
                <>
                    <TextField
                        required
                        fullWidth
                        name="password"
                        label="Mot de passe"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        error={fieldHasError('plainPassword.children.first')}
                        helperText={fieldError('plainPassword.children.first')}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <TextField
                        required
                        fullWidth
                        name="confirm-password"
                        label="Confirmation mot de passe"
                        type="password"
                        id="confirm-password"
                        autoComplete="new-password"
                        error={fieldHasError('plainPassword.children.second')}
                        helperText={fieldError('plainPassword.children.second')}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Réinitiliser
                    </Button>
                </>
            )
        }
        return null
    }, [data, error, fieldError, fieldHasError])
    return (
        <Container component="main" maxWidth="xs" >

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h6">
                    Réinitialiser le mot de passe
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    {/* @ts-ignore */}
                    {error?.data && error?.data?.message && (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {/* @ts-ignore */}
                            {error?.data?.message}
                        </Alert>
                    )}

                    {renderForm}
                </Box>
            </Box>
        </Container>
    );
}