import IssueItem from '@/components/IssueItem';
import { useCategoriesQuery } from '@/stores/api';
import { Issue } from '@/stores/types';
import { generateToken } from '@/utils/reader';
import { Box, CircularProgress, Container, Grid } from '@mui/material';

import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

interface Props {
}

export default function Home(props: Props) {

    const { data: categories, isLoading } = useCategoriesQuery()

    const Loading = useMemo(() => {
        if (isLoading) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <CircularProgress color='primary' />
                </Box>
            )
        }
        return null;
    }, [isLoading])

    const onPressIssue = (issue: Issue) => () => {
        const jwt = generateToken(issue)
        window.location.href = `${process.env.REACT_APP_READER_URL}${jwt}`
    }

    return (
        <Container maxWidth={false} sx={{ flex: 1, }}>
            {Loading}

            {
                categories?.map(category => {
                    return (
                        <Box key={category.id}>
                            <Typography variant={"h4"} sx={{ my: 2 }}>{category.name}</Typography>

                            <Grid
                                container
                                columnSpacing={2}
                                rowSpacing={3}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch">
                                {category.items.map((item, i) => {
                                    const { publicationNumber } = item;
                                    if (!publicationNumber) {
                                        return null;
                                    }
                                    return (
                                        <Grid key={i} item xs={6} sm={3} md={2} >

                                            <IssueItem
                                                title={item.name}
                                                image={publicationNumber?.coverUrl!}
                                                publishedAt={publicationNumber?.dateParution}
                                                onClick={onPressIssue(publicationNumber!)}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    )
                })
            }
        </Container >
    );
}