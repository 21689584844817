import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


interface Props {
}

export default function Account(props: Props) {

    return (
        <Box component="main" sx={{ p: 3 }}>
            <Typography>
                Account
            </Typography>

        </Box>
    );
}