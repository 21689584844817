import dayjs from 'dayjs';
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
// import isNextWeek from 'utils/dayjs/isNextWeek';

// import { I18nManager, } from 'react-native';
// import { Constants } from 'react-native-ui-lib';
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import * as RNLocalize from "react-native-localize";
// import 'intl-pluralrules'

// const resources = {
//     en: { translation: () => require('./locale/en.json') },
//     es: { translation: () => require('./locale/es.json') },
//     fr: { translation: () => require('./locale/fr.json') },
// }
const fallback = { languageTag: "fr", }; // here is your fallback in case findBestAvailableLanguage returns undefined

// const { languageTag, } = RNLocalize.findBestAvailableLanguage(Object.keys(resources)) ?? fallback;

// I18nManager.forceRTL(Constants.isRTL);

//set moment locale
dayjs.locale(fallback.languageTag)
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)
// dayjs.extend(isNextWeek)

// i18n
//     .use(initReactI18next) // passes i18n down to react-i18next
//     .init({
//         compatibilityJSON: 'v4',
//         resources: {
//             en: { translation: require('./locale/en.json') },
//             es: { translation: require('./locale/es.json') },
//             fr: { translation: require('./locale/fr.json') },
//         },
//         lng: languageTag, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//         // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//         // if you're using a language detector, do not define the lng option
//         fallbackLng: 'fr',
//         // returnObjects:true,
//         debug: __DEV__,
//         interpolation: {
//             escapeValue: false // react already safes from xss
//         }
//     });
// i18n.on('missingKey', function (lngs, namespace, key, res) {
//     __DEV__ && console.log({ lngs, namespace, key, res })
// })

// export default i18n;
