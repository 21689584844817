import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Fab, Fade, useScrollTrigger } from '@mui/material';
import Footer from './components/Footer';
import { Link, Outlet } from 'react-router-dom';
import { Fragment, useMemo, useState } from 'react';
import useAuthententicatedProfile from './hooks/useAuthententicatedProfile';

// import '@/assets/fonts/_fonts.scss'

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

interface ScrollTopProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children?: React.ReactNode;
}
function ScrollTop(props: ScrollTopProps) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}


export default function Layout(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const profile = useAuthententicatedProfile()

    const avatar = useMemo(() => {
        if (profile) {
            if (profile?.avatar) {
                return profile?.avatar

            } else {
                return profile?.kiosk?.logoUrl
            }
        }

        return null
    }, [profile])

    const kioskLogo = useMemo(() => {
        // if (profile) {
        //     if (profile?.kiosk?.logoUrl) {
        //         return profile?.kiosk?.logoUrl
        //     }
        // }

        return require('@/assets/images/ubeeqo.png')
    }, [profile?.kiosk])


    return (
        <Fragment>
            <Box
            // sx={{ display: 'flex', minHeight: '100vh', }}
            >
                <AppBar position="static" color="primary" enableColorOnDark>
                    <Toolbar
                        sx={{
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                flexGrow: 1,
                                textAlign: 'center',
                                display: { sm: 'block' },
                                '& img': {
                                    // maxHeight: 200,
                                    // marginRight: '10px',
                                    width: '100%',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                }
                            }}
                        >
                            <Link to={"/"}>
                                <img
                                    src={kioskLogo}
                                    alt="Logo"
                                />
                            </Link>
                        </Typography>
                        <Typography variant="h6" mb={2} sx={{ textAlign: 'center' }}>
                            Ubeeqo a le plaisir de vous offrir un accès gratuit à une sélection de presse numérique. Bonne lecture !!
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    minHeight: '100vh',
                }}>
                    <Toolbar id="back-to-top-anchor" />
                    <Outlet />
                </Box>

            </Box>
            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <Footer />
        </Fragment>
    );
}